<template>
  <b-modal
    :visible="showModal"
    :title="title"
    :ok-title="okTitle"
    :ok-disabled="loadingSubmit"
    cancel-variant="white"
    cancel-title="Batal"
    :centered="centered"
    @ok="okAction"
    @hidden="hideModal"
  >
    <slot />
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    okTitle: {
      type: String,
      default: '',
    },
    okAction: {
      type: Function,
      default: () => {},
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideModal() {
      this.$emit('update:showModal', false)
    },
  },
}
</script>
