<template>
  <div class="d-flex align-items-center">
    <span
      v-if="!isLargeText && !isMediumText"
      class="card-text"
    >{{
      value
    }}</span>
    <h5
      v-else-if="isMediumText"
      class="mb-0"
    >
      {{ value }}
    </h5>
    <h4
      v-else
      class="mb-0"
    >
      {{ value }}
    </h4>
    <!-- Copy text -->
    <!-- should have value, array should have item(s) -->
    <!-- or not an array but have value-->
    <div
      v-if="
        showCopy &&
          hasValue &&
          (value.length || typeof value !== 'object')
      "
      class="position-relative"
    >
      <b-button
        ref="btn-copy"
        variant="flat-dark"
        class="btn-icon btn-copy ml-50 pt-0"
        size="sm"
        @click.prevent="copyText(value, $event)"
      >
        <feather-icon
          icon="CopyIcon"
          class="pointer-events-none"
          size="16"
        />
      </b-button>
      <!-- Tooltip -->
      <b-tooltip
        ref="tooltip-copy"
        :target="() => $refs['btn-copy']"
        title="Disalin!"
        placement="top"
        no-fade
        disabled
      />
    </div>
  </div>
</template>

<script>
import { copy } from '@/helper/clipboard'
import { BButton, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BTooltip,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: true,
    },
    isLargeText: {
      type: Boolean,
      default: false,
    },
    isMediumText: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyText(val) {
      copy(val.toString()).then(() => {
        // show tooltip only when success
        this.$refs['tooltip-copy'].$emit('open')

        // hide tooltip after half a second
        setTimeout(() => {
          this.$refs['tooltip-copy'].$emit('close')
        }, 500)
      })
    },
  },
}
</script>
