<template>
  <div class="d-flex w-100 justify-content-center">
    <b-button
      v-if="shouldShowButton"
      variant="primary"
      class="mt-1"
      @click="loadMore"
    >
      Muat Lebih Banyak
    </b-button>
    <p
      v-if="shouldShowEndMessage"
      class="m-0 p-1 text-center"
    >
      {{ endMessage }}
    </p>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'LoadMoreButton',
  components: { BButton },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    endMessage: {
      type: String,
      default: 'Tidak ada lagi item yang bisa dimuat',
    },
  },
  computed: {
    shouldShowButton() {
      return this.lastPage !== this.currentPage && !this.isLoading
    },
    shouldShowEndMessage() {
      return this.lastPage === this.currentPage && !this.isLoading && this.currentPage !== 1
    },
  },
  methods: {
    loadMore() {
      this.$emit('loadMore')
    },
  },
}
</script>
