<template>
  <b-card title="Daftar Alamat">
    <div
      v-if="$store.getters['address/addressList'].length"
      class="d-flex flex-column align-items-center"
    >
      <b-card
        v-for="(item, index) in sortedAddressList"
        :key="index"
        :border-variant="item.is_default || item.is_registry_address ? 'primary' : 'secondary'"
        class="mx-0 mb-1 w-100"
      >
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="font-medium-2">
              {{ item.name }}
            </h4>
            <div>
              <b-badge
                v-if="item.is_default"
                class="font-weight-bold"
                variant="primary"
              >
                Utama
              </b-badge>
              <b-badge
                v-if="item.is_registry_address"
                class="font-weight-bold ml-1"
                variant="primary"
              >
                Registry
              </b-badge>
            </div>
          </div>
          <h6 class="font-medium-1 font-weight-light">
            {{ item.phone }}
          </h6>
          <p class="font-medium-1 font-weight-light mb-0">
            {{ item.address }}
          </p>

          <div class="c-options-responsive">
            <div class="d-flex justify-content-between align-items-center mt-1">
              <div class="w-100">
                <b-button
                  variant="primary"
                  class="mr-1 w-100"
                  @click="showModalEdit(item.id)"
                >
                  Ubah Alamat
                </b-button>
              </div>
              <div
                v-if="!item.is_default"
                class="ml-1"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  class="chart-dropdown"
                  toggle-class="p-0"
                  dropleft
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item
                    @click="confirmChange(item.id, 'utama')"
                  >
                    Jadikan Alamat Utama
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!item.is_registry_address && !item.is_default"
                    @click="confirmChange(item.id, 'registry')"
                  >
                    Jadikan Alamat Registry
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDelete(item.id)">
                    Hapus
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="c-options">
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-button
                variant="transparent"
                size="sm"
                class="mr-1 p-0 border-0 text-primary font-weight-bold"
                @click="showModalEdit(item.id)"
              >
                Ubah Alamat
              </b-button>

              <b-button
                v-if="!item.is_default"
                variant="transparent"
                size="sm"
                class="mr-1 p-0 border-0 text-primary font-weight-bold"
                @click="confirmChange(item.id, 'utama')"
              >
                Jadikan Alamat Utama
              </b-button>

              <b-button
                v-if="!item.is_registry_address && !item.is_default"
                variant="transparent"
                size="sm"
                class="mr-1 p-0 border-0 text-primary font-weight-bold"
                @click="confirmChange(item.id, 'registry')"
              >
                Jadikan Alamat Registry
              </b-button>

              <b-button
                v-if="!item.is_default"
                variant="transparent"
                size="sm"
                class="mr-1 p-0 border-0 text-primary font-weight-bold"
                @click="confirmDelete(item.id)"
              >
                Hapus
              </b-button>
            </div>
          </div>
        </div>
      </b-card>
      <div>
        <load-more-button
          :total-items="$store.getters['address/addressList'].length"
          :last-page="$store.state.address.lastPage"
          :current-page="$store.state.address.currentPage"
          :is-loading="$store.state.address.loadingAddress"
          @loadMore="loadMore"
        />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <b-spinner
        v-if="$store.state.address.loadingAddress"
        label="Loading cart..."
      />
    </div>
    <p
      v-if="!$store.getters['address/addressList'].length && !$store.state.address.loadingAddress"
      class="m-0 p-1 text-center"
    >
      Alamat kosong
    </p>
    <Modal
      :show-modal.sync="deleteModalVisible"
      title="Hapus data"
      ok-title="Ya, Yakin"
      :ok-action="deleteData"
      :loading-submit="$store.state.address.loadingSubmit"
    >
      Tindakan ini tidak bisa dibatalkan. Apakah Anda yakin?
    </Modal>

    <Modal
      :show-modal.sync="changeModalVisible"
      title="Ubah Alamat"
      ok-title="Konfirmasi"
      :ok-action="changeAddress"
      :loading-submit="$store.state.address.loadingSubmit"
    >
      Konfirmasi ubah alamat {{ typeAddress }}?
    </Modal>

    <Modal
      :show-modal.sync="editModalVisible"
      title="Ubah Alamat"
      :loading-submit="$store.state.address.loadingSubmit"
      ok-title="Simpan"
      :centered="true"
      :ok-action="updateAddress"
    >
      <b-form-group
        label="Nama Penerima"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="addressData.name"
          trim
          placeholder="Fulan bin Fulan"
        />
      </b-form-group>

      <b-form-group
        label="Nomor Telepon"
        label-for="phone"
      >
        <b-form-input
          id="phone"
          v-model="addressData.phone"
          trim
          placeholder="081234567890"
        />
      </b-form-group>

      <b-form-group
        label="Kecamatan Dan Kota"
        label-for="district-id"
      >
        <v-select
          v-model="addressData.district"
          label="label"
          :options="listDistricts"
          placeholder="Jakarta Pusat, DKI Jakarta"
          @search="getDistricts"
        >
          <span
            v-if="$store.state.address.loadingDistrict"
            slot="no-options"
          >
            Memuat..
          </span>
          <span
            v-else
            slot="no-options"
          >
            Ketik untuk mulai mencari..
          </span>
        </v-select>
      </b-form-group>

      <b-form-group
        label="Alamat"
        label-for="address"
      >
        <b-form-textarea
          id="address"
          v-model="addressData.address"
          trim
          placeholder="Jalan Proklamasi No. 1, Blok A, RT 01 RW 01, Kelurahan Menteng, Kecamatan Menteng, Kota Jakarta Pusat, DKI Jakarta, 10310"
        />
      </b-form-group>

      <b-form-group
        label="Kode Pos"
        label-for="postal-code"
      >
        <b-form-input
          id="postal-code"
          v-model="addressData.postal_code"
          trim
          placeholder="53234"
        />
      </b-form-group>
    </Modal>
  </b-card>
</template>
<script>
import {
  BBadge,
  BButton, BCard, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoadMoreButton from '@/layouts/components/LoadMore.vue'
import vSelect from 'vue-select'
import Modal from '@/layouts/components/Modal.vue'

export default {
  components: {
    Modal,
    LoadMoreButton,
    BSpinner,
    BFormTextarea,
    BBadge,
    BDropdownItem,
    BDropdown,
    BFormInput,
    BFormGroup,
    BButton,
    BCard,
    vSelect,
  },
  data() {
    return {
      deleteModalVisible: false,
      editModalVisible: false,
      changeModalVisible: false,
      listDistricts: [],
      typeAddress: null,
      selectedDistrict: '',
      addressData: {
        name: null,
        phone: null,
        district: null,
        district_id: null,
        address: null,
        postal_code: null,
      },
    }
  },
  computed: {
    sortedAddressList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.getters['address/addressList'].sort((a, b) => {
        // First sort by is_default
        if (a.is_default !== b.is_default) {
          return b.is_default - a.is_default
        }
        // If is_default is the same, sort by is_registry_address
        return b.is_registry_address - a.is_registry_address
      })
    },
  },
  mounted() {
    this.getDataAddress()
  },
  methods: {
    getDataAddress() {
      return this.$store.dispatch('address/getListAddress', {
        id: this.$route.params.id,
        page: this.$store.state.address.currentPage,
        limit: 5,
      })
    },
    loadMore() {
      this.$store.state.address.currentPage += 1
      this.getDataAddress()
    },
    confirmChange(id, type = 'utama') {
      this.selectedData = id
      this.typeAddress = type
      this.changeModalVisible = true
    },
    changeDefault(id, callback) {
      return this.$store.dispatch('address/changeDefault', id)
        .then(callback)
    },
    changeRegistry(id, callback) {
      return this.$store.dispatch('address/changeRegistryAddress', id).then(callback)
    },
    changeAddress() {
      if (this.typeAddress === 'utama') {
        this.changeDefault(this.selectedData, () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Berhasil menjadikan alamat sebagai alamat utama',
              variant: 'success',
            },
          })

          this.changeModalVisible = false
        })
      } else {
        this.changeRegistry(this.selectedData, () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Berhasil menjadikan alamat sebagai alamat registry',
              variant: 'success',
            },
          })

          this.changeModalVisible = false
        })
      }
    },
    getDistricts(input) {
      this.$store.dispatch('address/getDistricts', input).then(() => {
        this.listDistricts = this.$store.getters['address/districtsList']
      })
    },
    showModalEdit(id) {
      this.selectedData = id
      const address = this.$store.getters['address/addressList'].find(item => item.id === id)
      this.addressData.name = address.name
      this.addressData.phone = address.phone
      this.addressData.district = `${address.district.name}, ${address.city.name}, ${address.province.name}`
      this.addressData.district_id = address.district.id
      this.addressData.address = address.address
      this.addressData.postal_code = address.postal_code
      this.editModalVisible = true
    },
    updateAddress(event) {
      event.preventDefault()
      const address = {
        name: this.addressData.name,
        phone: this.addressData.phone,
        address: this.addressData.address,
        postal_code: this.addressData.postal_code,
        district_id: this.addressData.district.district_id ? this.addressData.district.district_id : this.addressData.district_id,
      }
      this.$store.dispatch('address/updateAddress', { id: this.selectedData, address })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Berhasil mengubah alamat!',
              variant: 'success',
            },
          })
        }).finally(() => {
          this.editModalVisible = false
        })
    },
    delete(id, callback) {
      return this.$store.dispatch('address/deleteAddress', id)
        .then(callback)
    },
    confirmDelete(id) {
      this.selectedData = id
      this.deleteModalVisible = true
    },
    deleteData() {
      this.delete(this.selectedData, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: 'Berhasil dihapus!',
            variant: 'success',
          },
        })

        this.deleteModalVisible = false
      })
    },
  },
}
</script>

<style scoped>
.c-options-responsive {
  display: none;
}

.c-options {
  display: block;
}

@media screen and (max-width: 768px) {
.c-options-responsive {
  display: block;
}
}

@media screen and (max-width: 768px) {
.c-options {
  display: none;
}
}
</style>
