<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Daftar Keranjang</b-card-title>
      <div>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.getters['cart/countCart'].data ? $store.getters['cart/countCart'].data : '0' }} Barang
        </b-badge>
      </div>
    </b-card-header>

    <b-card-body>
      <div
        v-if="$store.getters['cart/cartList'].length"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <b-media
          v-for="(item, itemIndex) in cartList"
          :key="itemIndex"
          class="mb-1 w-100"
        >
          <template #aside>
            <b-img
              :src="
                item.sku.image_url ? item.sku.image_url : item.product.image_url + '?tr=w-80,h-80,fo-auto'
              "
              :alt="item.product.name"
              class="rounded"
              width="80"
              height="80"
            />
          </template>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <div class="media-heading">
                <h6 class="cart-item-title">
                  <b-link class="text-body line-clamp">
                    {{ item.product.name }}
                  </b-link>
                </h6>
                <p class="cart-item-desc mb-0">
                  {{ item.sku.sub_variant ? item.sku.sub_variant.name : '' }}
                </p>
                <div
                  class=" d-flex "
                >
                  <div class="cart-item-qty mr-1 mt-1">
                    <b-form-spinbutton
                      v-model="item.quantity"
                      min="1"
                      size="sm"
                      @change="
                        () =>
                          debouncedUpdateQtyCart(
                            item.id,
                            item.quantity,
                            item.sku.stock
                          )
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-column align-items-end justify-content-center"
              >
                <h5 class="cart-item-price">
                  {{ formatPrice(item.sku.price * item.quantity) }}
                </h5>
                <b-button
                  v-b-tooltip.v-danger.top="'Delete'"
                  variant="flat-dark"
                  class="btn-icon"
                  size="sm"
                  @click="confirmDelete(item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="pointer-events-none"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </b-media>

        <load-more-button
          :total-items="$store.getters['cart/cartList'].length"
          :last-page="$store.state.cart.lastPage"
          :current-page="$store.state.cart.currentPage"
          :is-loading="$store.state.cart.loadingCart"
          @loadMore="loadMore"
        />
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <b-spinner
          v-if="$store.state.cart.loadingCart"
          label="Loading cart..."
        />
      </div>
      <p
        v-if="!$store.getters['cart/cartList'].length && !$store.state.cart.loadingCart"
        class="m-0 p-1 text-center"
      >
        Keranjang kosong
      </p>
    </b-card-body>
    <b-modal
      v-model="deleteModalVisible"
      title="Hapus data"
      ok-title="Ya, Yakin"
      cancel-variant="white"
      cancel-title="Batal"
      @ok="deleteData"
    >
      Tindakan ini tidak bisa dibatalkan. Apakah Anda yakin?
    </b-modal>
  </b-card>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormSpinbutton,
  BImg,
  BLink,
  BMedia, BModal, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from 'lodash'
import LoadMoreButton from '@/layouts/components/LoadMore.vue'

export default {
  components: {
    LoadMoreButton,
    BSpinner,
    BModal,
    BButton,
    BFormSpinbutton,
    BLink,
    BImg,
    BCardBody,
    BMedia,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCard,
  },
  data() {
    return {
      deleteModalVisible: false,
      items: [],
    }
  },
  computed: {
    cartList() {
      return this.$store.getters['cart/cartList']
    },
  },
  mounted() {
    this.countCart()
    this.getDataCart()
  },
  methods: {
    getDataCart() {
      return this.$store.dispatch('cart/getListCart', {
        id: this.$route.params.id,
        page: this.$store.state.cart.currentPage,
        limit: 5,
      })
    },
    loadMore() {
      this.$store.state.cart.currentPage += 1
      this.getDataCart()
    },
    updateQtyCart(id, newQuantity) {
      this.$store.dispatch('cart/updateQtyCart', {
        id,
        data: { quantity: newQuantity },
      })
    },
    debouncedUpdateQtyCart: debounce(function (id, newQuantity, stock) {
      if (newQuantity > stock) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            text: 'Stok tidak mencukupi!',
            variant: 'danger',
          },
        })
        // Set the quantity back to the stock if the new quantity is greater than the stock
        this.cartList.find(item => item.id === id).quantity = stock
      } else {
        this.updateQtyCart(id, newQuantity)
      }
    }, 500),
    delete(id, callback) {
      return this.$store.dispatch('cart/deleteCart', id)
        .then(callback).finally(() => {
          this.countCart()
        })
    },
    confirmDelete(id) {
      this.selectedData = id
      this.deleteModalVisible = true
    },
    deleteData() {
      this.delete(this.selectedData, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: 'Berhasil dihapus!',
            variant: 'success',
          },
        })

        this.deleteModalVisible = false
      })
    },
    countCart() {
      this.$store.dispatch('cart/getCountCart', this.$route.params.id)
    },
    formatPrice(price) {
      const formattedPrice = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(price)
      return formattedPrice.slice(0, -3)
    },
  },
}
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
