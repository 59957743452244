<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        lg="8"
        md="6"
      >
        <Profile />
      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="4"
        md="6"
      >
        <FavoriteGroupList />
      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
      >
        <CartList />
      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
      >
        <AddressList />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Profile from '@/layouts/components/Profile/Profile.vue'
import FavoriteGroupList from '@/layouts/components/Profile/FavoriteGroupList.vue'
import CartList from '@/layouts/components/Profile/CartList.vue'
import AddressList from '@/layouts/components/Profile/AddressList.vue'

import {
  BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    AddressList,
    CartList,
    FavoriteGroupList,
    BRow,
    BCol,
    Profile,
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('user/getDetail', id)
    },
  },
}
</script>

<style scoped>

</style>
