<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Profile</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item :to="`/user/${$store.getters['user/detailData'].id}/edit`">
          Ubah
        </b-dropdown-item>
        <b-dropdown-item
          @click.prevent="showModalRequestOtp($store.getters['user/detailData'].email)"
        >
          Permintaan Sandi Baru
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Ubah Role
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Pergi ke Profil
        </b-dropdown-item>
        <hr class="m-0 pb-0">
        <b-dropdown-item href="#">
          Hapus
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="12"
          class="d-flex justify-content-start flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex flex-column flex-xl-row align-items-center">
            <b-avatar
              :src="$store.getters['user/detailData'].image_url + '?tr=w-300,h-300,fo-auto'"
              :text="avatarText($store.getters['user/detailData'].name)"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1 mt-1 mt-xl-0 align-items-center align-items-xl-start">
              <div class="mb-1 d-flex flex-column align-items-center align-items-xl-start">
                <ClipboardButton
                  :value="$store.getters['user/detailData'].name"
                  :show-copy="true"
                  :has-value="true"
                  is-large-text
                />
                <ClipboardButton
                  :value="$store.getters['user/detailData'].email"
                  :show-copy="true"
                  :has-value="true"
                />
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="`/user/${$store.getters['user/detailData'].id}/edit`"
                  variant="primary"
                  size="sm"
                  class="d-flex align-items-center justify-content-center"
                >
                  Ubah
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  size="sm"
                >
                  <span class="align-middle">Pergi ke Profil</span>
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="15"
                    class="text-body cursor-pointer ml-50"
                  />
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <!--          <div class="d-flex flex-row mx-auto mx-xl-0 mt-2">-->
          <!--            <div class="d-flex align-items-center mr-2">-->
          <!--              <b-avatar-->
          <!--                variant="light-primary"-->
          <!--                rounded-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  icon="DollarSignIcon"-->
          <!--                  size="18"-->
          <!--                />-->
          <!--              </b-avatar>-->
          <!--              <div class="ml-1">-->
          <!--                <small>-->
          <!--                  <span class="text-body">Monthly Sales</span>-->
          <!--                  <feather-icon-->
          <!--                    icon="ExternalLinkIcon"-->
          <!--                    size="15"-->
          <!--                    class="text-body cursor-pointer ml-50"-->
          <!--                  />-->
          <!--                </small>-->
          <!--                <clipboard-button-->
          <!--                  :value="`23.3k`"-->
          <!--                  show-copy-->
          <!--                  has-value-->
          <!--                  is-medium-text-->
          <!--                />-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="d-flex align-items-center">-->
          <!--              <b-avatar-->
          <!--                variant="light-success"-->
          <!--                rounded-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  icon="TrendingUpIcon"-->
          <!--                  size="18"-->
          <!--                />-->
          <!--              </b-avatar>-->
          <!--              <div class="ml-1">-->
          <!--                <small>-->
          <!--                  <span class="text-body">Annual Profit</span>-->
          <!--                  <feather-icon-->
          <!--                    icon="ExternalLinkIcon"-->
          <!--                    size="15"-->
          <!--                    class="text-body cursor-pointer ml-50"-->
          <!--                  />-->
          <!--                </small>-->
          <!--                <clipboard-button-->
          <!--                  :value="`$99.87k`"-->
          <!--                  show-copy-->
          <!--                  has-value-->
          <!--                  is-medium-text-->
          <!--                />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </b-col>
      </b-row>

      <div class="px-2 pt-1">
        <FormView
          :data="$store.getters['user/detailData']"
          :fields="$store.getters['user/tableFields']"
          @load="loadData"
        />
      </div>
    </b-card-body>
    <b-modal
      v-model="requestOtpModalVisible"
      title="Permintaan sandi baru"
      ok-title="Ya"
      cancel-variant="white"
      cancel-title="Batal"
      @ok="requestOtp"
    >
      Kirim permintaan OTP untuk memperbarui kata sandi ke email ini?
    </b-modal>
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BCard, BRow, BCol, BAvatar, BButton, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BModal,
} from 'bootstrap-vue'
import { copy } from '@/helper/clipboard'
import ClipboardButton from '@/layouts/components/ClipboardButton.vue'
import FormView from '@/layouts/components/FormView.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardBody,
    BDropdownItem,
    BDropdown,
    BCardTitle,
    BCardHeader,
    FormView,
    ClipboardButton,
    BRow,
    BCard,
    BCol,
    BAvatar,
    BButton,
  },
  data() {
    return {
      avatarText,
      requestOtpModalVisible: false,
    }
  },
  computed: {
    defaultImage() {
      if (!this.userData) {
        return null
      }

      return this.$options.filters.thumbnail(this.userData.avatar, 40)
    },
  },
  mounted() {
    this.loadData(this.$route.params.id)
  },
  methods: {
    copyText(val) {
      copy(val.toString())
        .then(() => {
          // show tooltip only when success
          this.$refs['tooltip-copy'].$emit('open')

          // hide tooltip after half a second
          setTimeout(() => {
            this.$refs['tooltip-copy'].$emit('close')
          }, 500)
        })
    },
    loadData(id) {
      return this.$store.dispatch('user/getDetail', id)
    },
    showModalRequestOtp(email) {
      this.selectedData = email
      this.requestOtpModalVisible = true
    },
    requestOtp() {
      this.otp(this.selectedData, () => {
        this.deleteModalVisible = false
      })
    },
    otp(email) {
      this.$store.dispatch('user/requestOtp', email).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: `OTP berhasil dikirim ke ${email}, silahkan beri tahu user untuk mengecek emailnya!`,
            variant: 'success',
          },
        })
      }).catch(error => {
        if (error.response && error.response.status === 429) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertTriangleIcon',
              text: 'Terlalu banyak permintaan. Tunggu sebentar dan coba lagi.',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertTriangleIcon',
              text: `Terjadi kesalahan: ${error.message}`,
              variant: 'danger',
            },
          })
        }
      }).finally(() => {
        this.requestOtpModalVisible = false
      })
    },
  },
}
</script>

<style scoped>

</style>
