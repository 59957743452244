<template>
  <b-card title="Daftar Favorit">
    <b-card-body
      v-if="$store.getters['favoriteCollections/collectionList'].length"
      class="image-grid w-100 p-0"
    >
      <div
        v-for="(item, index) in collectionList"
        :key="index"
        class="w-100"
      >
        <router-link :to="`/user/${id}/favorite/${item.slug}`">
          <b-aspect>
            <div
              v-if="item.image_urls.length"
              class="image-collections h-100"
            >
              <div
                v-for="(image, indexImage) in item.image_urls"
                :key="indexImage"
                :class="{ 'item-three': item.image_urls.length === 3 && indexImage === 0, 'item-two': item.image_urls.length === 2, 'item-one' : item.image_urls.length === 1}"
              >
                <b-img
                  :src="`${image}` | thumbnail(200)"
                  alt="item.name"
                  class="w-100 border border-dashed"
                />
              </div>
            </div>
            <div
              v-else
              class="h-100 border-2 border-secondary no-product"
            >
              <div class="d-flex flex-column align-items-center justify-content-center h-100">
                <feather-icon
                  icon="ImageIcon"
                  class="pointer-events-none"
                  size="40"
                />
                <p class="mt-1">
                  Tidak ada produk
                </p>
              </div>
            </div>
          </b-aspect>
        </router-link>
        <div
          class="d-flex flex-row justify-content-between align-items-start mb-2 mt-1"
        >
          <div class="d-flex flex-column">
            <span
              href="#"
              class="font-weight-bold font-medium-2"
            >
              {{ item.name }}
            </span>
            <span class="card-text">{{ item.total || item.favorite_count }} Barang</span>
          </div>
        </div>
      </div>
    </b-card-body>
    <load-more-button
      :total-items="$store.getters['favoriteCollections/collectionList'].length"
      :last-page="$store.state.favoriteCollections.lastPage"
      :current-page="$store.state.favoriteCollections.currentPage"
      :is-loading="$store.state.favoriteCollections.loadingCollection"
      @loadMore="loadMore"
    />
    <div class="d-flex align-items-center justify-content-center">
      <b-spinner
        v-if="$store.state.favoriteCollections.loadingCollection"
        label="Loading favorite..."
      />
    </div>
    <p
      v-if="!$store.getters['favoriteCollections/collectionList'].length && !$store.state.favoriteCollections.loadingCollection"
      class="m-0 p-1 text-center"
    >
      Favorite kosong
    </p>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BImg, BSpinner, BAspect,
} from 'bootstrap-vue'
import { template } from 'lodash'
import LoadMoreButton from '@/layouts/components/LoadMore.vue'

export default {
  components: {
    LoadMoreButton,
    BAspect,
    BSpinner,
    BImg,
    BCard,
    BCardBody,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    collectionList() {
      return this.$store.getters['favoriteCollections/collectionList']
    },
  },
  mounted() {
    this.getDataCollections()
  },
  methods: {
    template,
    getDataCollections() {
      return this.$store.dispatch('favoriteCollections/getListCollection', {
        id: this.id,
        page: this.$store.state.favoriteCollections.currentPage,
        limit: 5,
      })
    },
    loadMore() {
      this.$store.state.favoriteCollections.currentPage += 1
      this.getDataCollections()
    },
  },
}
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
}
.image-collections {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-radius: 0.375rem;
}
.no-product {
  border-radius: 0.375rem;
}
.item-three {
  grid-row: span 2;
}
.item-three > img {
  object-fit: cover;
  height: 100%;
}
.item-two > img {
  object-fit: cover;
  height: 100%;
}
.item-one {
  grid-column: span 2;
  grid-row: span 2;
}
.item-one > img {
  object-fit: contain;
  height: 100%;
}
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
